<div>
  <task-overview [taskTitle]="taskName"></task-overview>
  <div class="body-container top10">
    <!-- Confirm items & log history -->
    <div class="flex flex-space-between top10">
      <div class="label-text">{{ questionTitle }}</div>
      <div>
        <div log-history [logs]="logHistory" [timezone]="stopTimeZone"></div>
      </div>
    </div>

    <!-- Call carrier & answer box -->
    <div>
      <!-- contact box -->
      <div class="top20">
        <div *ngIf="shouldContactDriver" contact-driver-box [contactInfo]="driverInfo"></div>
        <div *ngIf="!shouldContactDriver" contact-carrier-box [carrierId]="carrierId"></div>  
      </div>
      
      <!-- answers box -->
      <div class="answer-box top20">
        <div class="light-text">Select {{contactEntity}}'s response below</div>
        <div class="top10">
          <div answers-for-task-otp-confirmation
            [contactEntity]="contactEntity"
            (responseChange)="onResponseChange($event)"
          ></div>
        </div>
      </div>
    </div>
  </div>
  
</div>